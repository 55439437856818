/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import WebIcon from "@mui/icons-material/WebAsset";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "layouts/templates/Configurator/ConfiguratorRoot";

// Material Dashboard 2 PRO React context
import {
	useMaterialUIController,
	setOpenConfigurator,
	setTransparentSidenav,
	setWhiteSidenav,
	setMiniSidenav,
	setFixedNavbar,
	setSidenavColor,
	setDarkMode,
} from "context";

function Configurator() {
	const [controller, dispatch] = useMaterialUIController();
	const {
		openConfigurator,
		miniSidenav,
		fixedNavbar,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [disabled, setDisabled] = useState(false);
	const sidenavColors = [
		"primary",
		"dark",
		"info",
		"success",
		"warning",
		"error",
	];

	// Use the useEffect hook to change the button state for the sidenav type based on window size.
	useEffect(() => {
		// A function that sets the disabled state of the buttons for the sidenav type.
		function handleDisabled() {
			return window.innerWidth > 1200
				? setDisabled(false)
				: setDisabled(true);
		}

		// The event listener that's calling the handleDisabled function when resizing the window.
		window.addEventListener("resize", handleDisabled);

		// Call the handleDisabled function to set the state with the initial value.
		handleDisabled();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleDisabled);
	}, []);

	const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
	const handleTransparentSidenav = () => {
		setTransparentSidenav(dispatch, true);
		setWhiteSidenav(dispatch, false);
	};
	const handleWhiteSidenav = () => {
		setWhiteSidenav(dispatch, true);
		setTransparentSidenav(dispatch, false);
	};
	const handleDarkSidenav = () => {
		setWhiteSidenav(dispatch, false);
		setTransparentSidenav(dispatch, false);
	};
	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
	const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

	// sidenav type buttons styles
	const sidenavTypeButtonsStyles = ({
		functions: { pxToRem },
		palette: { white, dark, background },
		borders: { borderWidth },
	}) => ({
		height: pxToRem(39),
		background: darkMode ? background.sidenav : white.main,
		color: darkMode ? white.main : dark.main,
		border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

		"&:hover, &:focus, &:focus:not(:hover)": {
			background: darkMode ? background.sidenav : white.main,
			color: darkMode ? white.main : dark.main,
			border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
		},
	});

	// sidenav type active button styles
	const sidenavTypeActiveButtonStyles = ({
		functions: { pxToRem, linearGradient },
		palette: { white, gradients, background },
	}) => ({
		height: pxToRem(39),
		background: darkMode
			? white.main
			: linearGradient(gradients.dark.main, gradients.dark.state),
		color: darkMode ? background.sidenav : white.main,

		"&:hover, &:focus, &:focus:not(:hover)": {
			background: darkMode
				? white.main
				: linearGradient(gradients.dark.main, gradients.dark.state),
			color: darkMode ? background.sidenav : white.main,
		},
	});

	return (
		<ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
			<MDBox
				display="flex"
				justifyContent="space-between"
				alignItems="baseline"
				pt={4}
				pb={0.5}
				px={3}
			>
				<MDBox>
					<MDTypography variant="h5">
						Конфигуратор
						{/* Material UI */}
					</MDTypography>
					<MDTypography variant="body2" color="text">
						Варианты панели инструментов.
					</MDTypography>
				</MDBox>

				<Icon
					sx={({ typography: { size }, palette: { dark, white } }) => ({
						fontSize: `${size.lg} !important`,
						color: darkMode ? white.main : dark.main,
						stroke: "currentColor",
						strokeWidth: "2px",
						cursor: "pointer",
						transform: "translateY(5px)",
					})}
					onClick={handleCloseConfigurator}
				>
					close
				</Icon>
			</MDBox>

			<Divider />

			<MDBox pt={0.5} pb={3} px={3}>
				<MDBox>
					<MDTypography variant="h6">Цвета боковой панели</MDTypography>

					<MDBox mb={0.5}>
						{sidenavColors.map(color => (
							<IconButton
								key={color}
								sx={({
									borders: { borderWidth },
									palette: { white, dark, background },
									transitions,
								}) => ({
									width: "24px",
									height: "24px",
									padding: 0,
									border: `${borderWidth[1]} solid ${
										darkMode ? background.sidenav : white.main
									}`,
									borderColor: () => {
										let borderColorValue =
											sidenavColor === color && dark.main;

										if (darkMode && sidenavColor === color) {
											borderColorValue = white.main;
										}

										return borderColorValue;
									},
									transition: transitions.create("border-color", {
										easing: transitions.easing.sharp,
										duration: transitions.duration.shorter,
									}),
									backgroundImage: ({
										functions: { linearGradient },
										palette: { gradients },
									}) =>
										linearGradient(
											gradients[color].main,
											gradients[color].state
										),

									"&:not(:last-child)": {
										mr: 1,
									},

									"&:hover, &:focus, &:active": {
										borderColor: darkMode ? white.main : dark.main,
									},
								})}
								onClick={() => setSidenavColor(dispatch, color)}
							/>
						))}
					</MDBox>
				</MDBox>

				<MDBox mt={3} lineHeight={1}>
					<MDTypography variant="h6">Тип боковой панели</MDTypography>
					<MDTypography variant="button" color="text">
						Выберите тип боковой панели.
					</MDTypography>

					<MDBox
						sx={{
							display: "flex",
							mt: 2,
							mr: 1,
						}}
					>
						<MDButton
							color="dark"
							variant="gradient"
							onClick={handleDarkSidenav}
							disabled={disabled}
							fullWidth
							sx={
								!transparentSidenav && !whiteSidenav
									? sidenavTypeActiveButtonStyles
									: sidenavTypeButtonsStyles
							}
						>
							Темная
						</MDButton>
						<MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
							<MDButton
								color="dark"
								variant="gradient"
								onClick={handleTransparentSidenav}
								disabled={disabled}
								fullWidth
								sx={
									transparentSidenav && !whiteSidenav
										? sidenavTypeActiveButtonStyles
										: sidenavTypeButtonsStyles
								}
							>
								Прозрачная
							</MDButton>
						</MDBox>
						<MDButton
							color="dark"
							variant="gradient"
							onClick={handleWhiteSidenav}
							disabled={disabled}
							fullWidth
							sx={
								whiteSidenav && !transparentSidenav
									? sidenavTypeActiveButtonStyles
									: sidenavTypeButtonsStyles
							}
						>
							Светлая
						</MDButton>
					</MDBox>
				</MDBox>
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mt={3}
					lineHeight={1}
				>
					<MDTypography variant="h6">
						Фиксированная <br /> навигационная панель
					</MDTypography>

					<Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
				</MDBox>
				<Divider />
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					lineHeight={1}
				>
					<MDTypography variant="h6">Боковая мини-панель</MDTypography>

					<Switch checked={miniSidenav} onChange={handleMiniSidenav} />
				</MDBox>
				<Divider />
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					lineHeight={1}
				>
					<MDTypography variant="h6">Светлая / Темная</MDTypography>

					<Switch checked={darkMode} onChange={handleDarkMode} />
				</MDBox>
				<Divider />

				<MDBox mt={2} textAlign="center">
					<MDBox mb={1}>
						<MDTypography variant="h6">Наши контакты</MDTypography>
					</MDBox>

					<MDBox display="flex" justifyContent="center">
						<MDBox mr={1.5}>
							<MDButton
								component={Link}
								href="https://itecho.ru/"
								target="_blank"
								rel="noreferrer"
								color="dark"
							>
								{/* <WebIcon /> */}
								{/* &nbsp;  */}
								itecho.ru
							</MDButton>
						</MDBox>
						<MDButton
							component={Link}
							href="https://vk.com/itechogroup"
							target="_blank"
							rel="noreferrer"
							color="dark"
						>
							{/* <TwitterIcon /> */}
							{/* &nbsp;  */}
							ВКонтакте
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</ConfiguratorRoot>
	);
}

export default Configurator;
