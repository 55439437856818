/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const channelChartData = {
	labels: [" VK", " Яндекс", " Google Ads", " Реферал"],
	datasets: {
		label: "Projects",
		backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
		data: [15, 20, 12, 60],
	},
};

export default channelChartData;
