/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import ivana from "assets/images/ivana-square.jpg";
import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

const profilesListData = [
	{
		image: kal,
		name: "Полищук Артем",
		description: "в сети",
		action: {
			type: "internal",
			route: "/pages/profile/profile-overview",
			color: "info",
			label: "reply",
		},
	},
	{
		image: marie,
		name: "Сергеева Инна",
		description: "в сети",
		action: {
			type: "internal",
			route: "/pages/profile/profile-overview",
			color: "info",
			label: "reply",
		},
	},
	{
		image: ivana,
		name: "Ivanna",
		description: "в сети",
		action: {
			type: "internal",
			route: "/pages/profile/profile-overview",
			color: "info",
			label: "reply",
		},
	},
	{
		image: team4,
		name: "Peterson",
		description: "в сети",
		action: {
			type: "internal",
			route: "/pages/profile/profile-overview",
			color: "info",
			label: "reply",
		},
	},
	{
		image: team3,
		name: "Nick Daniel",
		description: "в сети",
		action: {
			type: "internal",
			route: "/pages/profile/profile-overview",
			color: "info",
			label: "reply",
		},
	},
];

export default profilesListData;
