/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import backgroundImage from "assets/images/bg-profile.jpeg";
import profilePicture from "assets/images/team-3.jpg";

function Header({ children }) {
	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [tabValue, setTabValue] = useState(0);

	useEffect(() => {
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		/** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
		window.addEventListener("resize", handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => setTabValue(newValue);

	return (
		<MDBox position="relative" mb={5}>
			<MDBox
				display="flex"
				alignItems="center"
				position="relative"
				minHeight="18.75rem"
				borderRadius="xl"
				sx={{
					backgroundImage: ({
						functions: { rgba, linearGradient },
						palette: { gradients },
					}) =>
						`${linearGradient(
							rgba(gradients.info.main, 0.6),
							rgba(gradients.info.state, 0.6)
						)}, url(${backgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
					overflow: "hidden",
				}}
			/>
			<Card
				sx={{
					position: "relative",
					mt: -8,
					mx: 3,
					py: 2,
					px: 2,
				}}
			>
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<MDAvatar
							src={profilePicture}
							alt="profile-image"
							size="xl"
							shadow="sm"
						/>
					</Grid>
					<Grid item>
						<MDBox height="100%" mt={0.5} lineHeight={1}>
							<MDTypography variant="h5" fontWeight="medium">
								Иванова Мария
							</MDTypography>
							<MDTypography
								variant="button"
								color="text"
								fontWeight="regular"
							>
								Менеджер
							</MDTypography>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
						<AppBar position="static">
							<Tabs
								orientation={tabsOrientation}
								value={tabValue}
								onChange={handleSetTabValue}
							>
								<Tab
									label="App"
									icon={
										<Icon fontSize="small" sx={{ mt: -0.25 }}>
											home
										</Icon>
									}
								/>
								<Tab
									label="Сообщения"
									icon={
										<Icon fontSize="small" sx={{ mt: -0.25 }}>
											email
										</Icon>
									}
								/>
								<Tab
									label="Настройки"
									icon={
										<Icon fontSize="small" sx={{ mt: -0.25 }}>
											settings
										</Icon>
									}
								/>
							</Tabs>
						</AppBar>
					</Grid>
				</Grid>
				{children}
			</Card>
		</MDBox>
	);
}

// Setting default props for the Header
Header.defaultProps = {
	children: "",
};

// Typechecking props for the Header
Header.propTypes = {
	children: PropTypes.node,
};

export default Header;
