/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const defaultLineChartData = {
	labels: [
		"Апр",
		"Май",
		"Июнь",
		"Июль",
		"Авг",
		"Сент",
		"Окт",
		"Нояб",
		"Дек",
		"Янв",
	],
	datasets: [
		{
			label: "Яндекс",
			color: "primary",
			data: [50, 100, 200, 190, 400, 350, 500, 450, 700],
		},
		{
			label: "Google Ads",
			color: "dark",
			data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
		},
	],
};

export default defaultLineChartData;
