/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

const { white } = colors;

function configs(backgroundColor) {
	return {
		data: {
			labels: [
				"Апр",
				"Май",
				"Июнь",
				"Июль",
				"Авг",
				"Сент",
				"Окт",
				"Нояб",
				"Дек",
				"Янв",
			],
			datasets: [
				{
					label: "Visitors",
					tension: 0.5,
					pointRadius: 0,
					borderColor: white.main,
					borderWidth: 2,
					backgroundColor,
					data: [50, 45, 60, 60, 80, 65, 90, 80, 100],
					maxBarThickness: 6,
					fill: true,
				},
			],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
				mode: "index",
			},
			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false,
					},
					ticks: {
						display: false,
					},
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false,
					},
					ticks: {
						display: false,
					},
				},
			},
		},
	};
}

export default configs;
