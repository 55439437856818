/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Configurator from "layouts/templates/Configurator";
import Sidenav from "layouts/templates/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import {
	setMiniSidenav,
	setOpenConfigurator,
	useMaterialUIController,
} from "context";

// Images
import brandDark from "assets/images/logo-ct-dark.png";
import brandWhite from "assets/images/logo-ct.png";
import brandItecho from "assets/images/logo-itecho.svg";

export default function App() {
	const [controller, dispatch] = useMaterialUIController();
	const {
		miniSidenav,
		direction,
		layout,
		openConfigurator,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const [rtlCache, setRtlCache] = useState(null);
	const { pathname } = useLocation();

	// Cache for the rtl
	useMemo(() => {
		const cacheRtl = createCache({
			key: "rtl",
			stylisPlugins: [rtlPlugin],
		});

		setRtlCache(cacheRtl);
	}, []);

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Change the openConfigurator state
	const handleConfiguratorOpen = () =>
		setOpenConfigurator(dispatch, !openConfigurator);

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = allRoutes =>
		allRoutes.map(route => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				return (
					<Route
						exact
						path={route.route}
						element={route.component}
						key={route.key}
					/>
				);
			}

			return null;
		});

	const configsButton = (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.25rem"
			height="3.25rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={99}
			color="dark"
			sx={{ cursor: "pointer" }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize="small" color="inherit">
				settings
			</Icon>
		</MDBox>
	);

	return direction === "rtl" ? (
		<CacheProvider value={rtlCache}>
			<ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
				<CssBaseline />
				{layout === "dashboard" && (
					<>
						<Sidenav
							color={sidenavColor}
							brand={
								(transparentSidenav && !darkMode) || whiteSidenav
									? brandDark
									: brandWhite
							}
							brandName="Material Dashboard PRO"
							routes={routes}
							onMouseEnter={handleOnMouseEnter}
							onMouseLeave={handleOnMouseLeave}
						/>
						<Configurator />
						{configsButton}
					</>
				)}
				{layout === "vr" && <Configurator />}
				<Routes>
					{getRoutes(routes)}
					<Route
						path="*"
						element={<Navigate to="/dashboards/analytics" />}
					/>
				</Routes>
			</ThemeProvider>
		</CacheProvider>
	) : (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			{layout === "dashboard" && (
				<>
					<Sidenav
						color={sidenavColor}
						// brand={
						// 	(transparentSidenav && !darkMode) || whiteSidenav
						// 		? brandDark
						// 		: brandWhite
						// }
						brand={brandItecho}
						brandName="Itecho demo"
						routes={routes}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
					<Configurator />
					{configsButton}
				</>
			)}
			{layout === "vr" && <Configurator />}
			<Routes>
				{getRoutes(routes)}
				<Route
					path="*"
					element={<Navigate to="/authentication/sign-in" />}
				/>
			</Routes>
		</ThemeProvider>
	);
}
